import React from "react"
import { Table } from "reactstrap"
import PageTemplate from "../components/pageTemplate"

import "bootstrap/dist/css/bootstrap.min.css"

let About = () => (
  <PageTemplate title="PRÉSENTATION">
    <p>
      Ste ait lhadj ben amouch est une entreprise générale de BTP qui vous
      assure la livraison de votre ouvrage, toutes fonctions et techniques
      assemblées, conforme et en état d’achèvement, au meilleur rapport
      qualité/prix. Nous vous accompagnons dès l’expression de vos besoins
      jusqu’à l’accomplissement des projets de vos travaux.
    </p>
    <p>
      La démarche qualité - environnement est devenue le quotidien de notre
      Entreprise, pérennisant ainsi la confiance que nous accordent nos clients
      dans notre capacité à respecter l'ensemble des engagements contractuels,
      réglementaires et techniques. La Prévention en matière d'hygiène et de
      sécurité est intégrée dès la préparation des projets afin d'aboutir à la
      meilleure sécurité de nos collaborateurs sur les chantiers.
    </p>
    <p>
      Ste ait lhadj ben amouch est par ailleurs très avancée en matière de
      transformation numérique, grâce à l’accès mobile aux données, et à la
      dématérialisation des documents.
    </p>
    <h2 className="mb-4">FICHE SIGNALÉTIQUE</h2>
    <Table striped bordered>
      <tbody>
        <tr>
          <th scope="row">Raison sociale</th>
          <td>Ait Lhadj Ben Amouch De Travaux</td>
        </tr>
        <tr>
          <th scope="row">Forme juridique </th>
          <td>S.A.R.L</td>
        </tr>
        <tr>
          <th scope="row">Capital Social </th>
          <td>2.000.000 DH</td>
        </tr>

        <tr>
          <th scope="row">Taxe Professionnelle </th>
          <td>26200272</td>
        </tr>
        <tr>
          <th scope="row">Identifiant Fiscale </th>
          <td>3303867</td>
        </tr>
        <tr>
          <th scope="row">Affiliation CNSS </th>
          <td>6725256</td>
        </tr>
        <tr>
          <th scope="row">Registre du commerce </th>
          <td>Rabat sous le N°58329</td>
        </tr>
        <tr>
          <th scope="row">Email </th>
          <td>benaamouch2004@gmail.com</td>
        </tr>
      </tbody>
    </Table>
  </PageTemplate>
)

export default About
